<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="提现审核"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item
          label="提现金额(元)"
          class="simiot-display-item"
        >
          {{ withdrawalAmount | formatFloat }}
        </a-form-item>

        <a-form-item label="状态">
          <a-select
            @change="handleStatusChange"
            v-decorator="['status', {
              rules: [
                { required: true, message: '请选择状态' }
              ]
            }]"
          >
            <a-select-option value="paid">
              已打款
            </a-select-option>
            <a-select-option value="reject">
              未通过
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item v-if="false" label="支付日期">
          <a-date-picker
            style="width: 100%;"
            :disabled-date="disabledPaidAt"
            v-decorator="['paid_at', {
              initialValue: this.$moment(),
              rules: [
                { required: true, message: '请选择支付日期' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item v-if="statusPaid" label="支付凭据截图" required>
          <span
            slot="extra"
            class="tip-text"
          >支持.jpg .jpeg .bmp .png格式照片，大小不超过10M</span>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.bmp,.png"
            @change="handlePayOrderChange"
            v-decorator="['pay_order', {rules: [
              { validator: checkPayOrder }
            ]
            }]"
          />
        </a-form-item>

        <a-form-item label="说明">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { required: true, message: '请输入说明' },
                { max: 200, message: '最多200个字符' }
              ]
            }]"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reviewAgentWithdrawal } from '@/api/withdrawal'
import UploadImage from '@/components/Upload/Image'
import { formatDate } from '@/utils/time'

export default {
  name: 'Review',
  components: { UploadImage },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    withdrawalId: {
      type: Number,
      required: true
    },
    withdrawalAmount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      payOrder: undefined,
      isAddWatermark: true, // 是否添加水印
      statusPaid: false,
      form: this.$form.createForm(this, { name: 'review_withdrawal' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    // 检查是否上传支付凭据
    checkPayOrder(rule, value, callback) {
      if (!this.payOrder || this.payOrder[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传支付凭据')
        return
      }

      callback()
    },

    disabledPaidAt(current) {
      return current && current > this.$moment().endOf('day')
    },

    handleStatusChange(value) {
      if (value === 'paid') {
        this.statusPaid = true
      } else {
        this.statusPaid = false
      }
    },

    handlePayOrderChange(fileList) {
      if (Object.keys(fileList).length > 0) {
        this.payOrder = fileList
      } else {
        this.payOrder = undefined
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            agent_id: this.agentId,
            paid_at: formatDate(values.paid_at)
          }

          if (this.payOrder === undefined) {
            delete data.pay_order
          } else {
            data.pay_order = this.payOrder[0].response
          }

          reviewAgentWithdrawal(this.withdrawalId, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
